.container {
    display: grid; 
    grid-template-columns: 1fr; 
    grid-template-rows: 1fr 1fr; 
    gap: 0px 0px; 
    flex-direction:row-reverse;
    justify-content: end; 
    justify-items: end; 
    grid-template-areas: 
      "btn"
      "text"; 
  }
  .btn { grid-area: btn;}
  .text { grid-area: text;}