.container {
    display: grid; 
    grid-template-columns: 1fr; 
    grid-template-rows: 1fr 1fr; 
    gap: 0px 0px; 
    flex-direction:row-reverse;
    justify-content: end; 
    justify-items: end; 
    grid-template-areas: 
      "btn"
      "text"; 
  }


  ul li {
    display: flex;
    list-style: none;
    color: #808080;
}


.civil-container {  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  
  gap: 20px 20px;
  grid-auto-flow: row dense;
  justify-content: stretch;
  justify-items: stretch;
  align-items: stretch;
 
}





  .btn { grid-area: btn;}
  .text { grid-area: text;}

  