.PjmLogoJumper {
    -webkit-animation: mover 3s infinite  alternate;
    animation: mover 3s infinite  alternate;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top:100px;
    width: 120px; 
}

.centerAll{
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-top:100px;
    width: 550px;
}


.centerButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 180px;
   
  }

.centerAllText{
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-top:50px;
    width: 350px;
}
@-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-20px); }
}
@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-20px); }
}

h3
{
    font-family: 'Source Code Pro', monospace;
   
}