.pdf a {
  color: #fff;
  text-decoration: none;

}



.containerregistro {  display: grid;
    grid-template-columns: 0.8fr 0.8fr 1.1fr 1.1fr 1.1fr;
    grid-template-rows: 0.27fr 0.27fr 0.27fr 0.27fr 0.27fr;
    gap: 6px 6px;
    grid-auto-flow: row dense;
    grid-template-areas:
      "img img apellido nombre nacimiento"
      "img img sexo edad municipio"
      "img img domicilio domicilio cp"
      "dni dni pais provincia ciudad" 
      
      "xx xx ejemplar emision vencimiento" 
      "pdf pdf pdf pdf pdf";; 



  }
  
  .img {
    grid-area: img;
    padding: 10px;
    overflow: hidden; 
    object-fit: cover; 
  }
  
  .img img{
    border-radius: 50%; 
    border:  solid #fff;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
}



  
  .apellido { grid-area: apellido; }
  
  .nombre { grid-area: nombre; }
  
  .dni { grid-area: dni;
         align-items: center;
         justify-content: center;
         display: flex; }   
  
  .nacimiento { grid-area: nacimiento; }
  
  .ejemplar { grid-area: ejemplar; }
  
  .emision { grid-area: emision; }
  
  .vencimiento { grid-area: vencimiento; }
  
  .pais { grid-area: pais; }
  
  .provincia { grid-area: provincia; }
  
  .ciudad { grid-area: ciudad; }
  
  .domicilio { grid-area: domicilio; }
  
  .cp { grid-area: cp; }
  
  .sexo { grid-area: sexo; }
  
  .nacionalidad { grid-area: municipio; }
  
  .edad { grid-area: edad; }
  
  .xx { grid-area: xx;
    align-items: inherit;
    justify-content: center;
    display: flex;
    font-size: 1em;
    font-weight: bold;
    
      }
  
  
  html, body , .containerregistro {
    height: 100%;
    margin: 0;
  }

  .pdf{grid-area: pdf;
    align-items: center;
    justify-content: center;
    display: flex;}
 
  
  
  